/* Header */
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.21) 0 3px 20px 0px;
  background: #fff;
  transform: translate3d(0, -80px, 0);
  transition: transform .4s ease;
  &.is-visible {
    transform: translate3d(0,0,0);
  }
  img {
    height: 40px;
    width: auto;
    display: block;
    margin: 10px auto 0;
  }
}