h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.44;
  margin-bottom: 30px;
  @include phone() {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.44;
  @include phone() {
    font-size: 2rem;
  }
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.44;
  text-transform: uppercase;
  @include phone() {
    font-size: 1.75rem;
  }
}

p, small {
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
  line-height: 1.7;
  @include phone() {
    font-size: 1.25rem;
  }
}

small {
  font-size: 14px;
  line-height: 1.44;
}

@media all and (max-width: 768px) {
  html {
    font-size: 85%;
  }
}