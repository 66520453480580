form {
  input.field, textarea {
    width: 100%;
    height: 60px;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    color: $blue;
    border: 1px dashed $blue;
    font-weight: 300;
    padding: 0 30px;
    margin-bottom: 15px;
    -webkit-appearance: none;
    &:focus {
      outline: none;
      border-width: 2px;
    }
    &.parsley-error {
      border-color: #ff5860;
      border-width: 2px;
    }
    @include phone() {
      font-size: 1.25rem;
      padding: 0 20px;
    }
  }

  textarea {
    height: 200px;
    font-size: 17px;
    padding: 20px;
    line-height: 1.3;
    resize: none;
    @include phone() {
      font-size: 1.25rem;
    }
  }

  .submit {
    display: block;
    -webkit-appearance: none;
    margin-top: 36px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid $blue;
    padding: 18px;
    line-height: 1;
    text-align: center;
    font-size: 14px;
    background: $blue;
    transition: background .3s ease, color .3s ease;
    cursor: pointer;
    letter-spacing: 0.04em;
    &:hover {
      background: #fff;
      color: $blue;
    }
  }
}

.checkbox-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid $blue;
  }

  // Box checked
  &:checked + label:before {
    background: $blue;
  }


  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.hidden-container {
  max-height: 0;
  overflow: hidden;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  will-change: max-height;
  transition: max-height .35s ease;
}

.parsley-errors-list {
  list-style: none;
  padding: 0;
  margin-top: 0;
  li {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: #ff5860;
    font-style: italic;
  }
}

.msg-container {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-top: 15px;
  span {
    display: block;
  }
  .success {
    color: green;
  }
  .error {
    color: #ff5860;
  }
  a {
    color: inherit;
  }
}