.main-footer {
  width: 100%;
  background: #000;
  padding: 15px 30px;

  small {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 300;
    color: #fff;
    &.right {
      float: right;
      a {
        color: #00d7ff;
        text-decoration: none;
      }
      @include phone() {
        float: initial;
        margin-top: 20px;
        display: block;
      }
    }
  }
}