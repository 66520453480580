/**
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html, body {
  width: 100%;
  overflow-x: hidden;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.44;
  margin-bottom: 30px;
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.44;
}

@media only screen and (max-width: 500px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.44;
  text-transform: uppercase;
}

@media only screen and (max-width: 500px) {
  h3 {
    font-size: 1.75rem;
  }
}

p, small {
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
  line-height: 1.7;
}

@media only screen and (max-width: 500px) {
  p, small {
    font-size: 1.25rem;
  }
}

small {
  font-size: 14px;
  line-height: 1.44;
}

@media all and (max-width: 768px) {
  html {
    font-size: 85%;
  }
}

body {
  padding: 85vh 0 0;
  margin: 0;
  backface-visibility: hidden;
}

@media only screen and (max-width: 500px) {
  body {
    padding: 50vh 0 0;
  }
}

.intro-container {
  width: 100%;
  height: 85vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 500px) {
  .intro-container {
    height: 50vh;
  }
}

.scroll-container {
  background: #fff;
  position: relative;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.21) 0 -4px 20px 0px;
}

.intro-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.intro-container img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  max-width: 60vw;
}

.intro-container a.scroll-down {
  padding-top: 70px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  display: inline-block;
  color: #000;
  font: normal 300 20px/1 'Lato', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s, transform 2s ease;
  transform: translate3d(0, 90px, 0);
  opacity: 0;
}

.intro-container a.scroll-down.show {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.intro-container a.scroll-down span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

.over {
  transition: opacity .6s ease .3s, visibility 0s ease .9s;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}

.box {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  padding: 72px;
}

.box.half {
  flex: 0 1 50vw;
  width: 50vw;
  padding-bottom: 140px;
}

.box.text h2 {
  margin-top: 60px;
}

@media only screen and (max-width: 500px) {
  .box.text h2 {
    margin-top: 0;
  }
}

@media all and (max-width: 920px) {
  .container {
    flex-wrap: wrap;
  }
  .container .box {
    flex: 0 1 100%;
    width: 100%;
    padding: 42px;
  }
}

.bg-blue {
  background: #035096;
  color: #fff;
}

.is ul {
  width: 100%;
  margin: 0;
  padding: 0;
}

.is ul li {
  display: inline-block;
  font-family: 'Lato', sans-serif;
}

.is ul li:after {
  content: ' / ';
  display: inline-block;
  font-size: 2rem;
  margin: 0 10px;
  color: #000000;
}

.is ul li.small {
  font-size: 1rem;
}

.is ul li.medium {
  font-size: 2rem;
}

.is ul li.big {
  font-size: 3rem;
}

.is ul li:last-child:after {
  display: none;
}

@media only screen and (max-width: 500px) {
  .is ul li.small {
    font-size: 1rem;
  }
  .is ul li.medium {
    font-size: 1.5rem;
  }
  .is ul li.big {
    font-size: 2rem;
  }
}

.sharing-container {
  padding: 36px 20px 80px 20px;
  background: #f1f1f1;
  text-align: center;
}

.sharing-container a {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 0 2vw;
}

@media only screen and (max-width: 500px) {
  .sharing-container a {
    margin: 0 40px 40px;
  }
}

.sharing-container a svg {
  height: 100%;
  transition: fill .2s ease;
  width: auto;
  fill: #000;
}

@media only screen and (max-width: 500px) {
  .sharing-container a svg {
    width: 50px;
  }
}

.sharing-container a:hover svg {
  fill: #035096;
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Header */
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.21) 0 3px 20px 0px;
  background: #fff;
  transform: translate3d(0, -80px, 0);
  transition: transform .4s ease;
}

.main-header.is-visible {
  transform: translate3d(0, 0, 0);
}

.main-header img {
  height: 40px;
  width: auto;
  display: block;
  margin: 10px auto 0;
}

form input.field, form textarea {
  width: 100%;
  height: 60px;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  color: #035096;
  border: 1px dashed #035096;
  font-weight: 300;
  padding: 0 30px;
  margin-bottom: 15px;
  -webkit-appearance: none;
}

form input.field:focus, form textarea:focus {
  outline: none;
  border-width: 2px;
}

form input.field.parsley-error, form textarea.parsley-error {
  border-color: #ff5860;
  border-width: 2px;
}

@media only screen and (max-width: 500px) {
  form input.field, form textarea {
    font-size: 1.25rem;
    padding: 0 20px;
  }
}

form textarea {
  height: 200px;
  font-size: 17px;
  padding: 20px;
  line-height: 1.3;
  resize: none;
}

@media only screen and (max-width: 500px) {
  form textarea {
    font-size: 1.25rem;
  }
}

form .submit {
  display: block;
  -webkit-appearance: none;
  margin-top: 36px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #035096;
  padding: 18px;
  line-height: 1;
  text-align: center;
  font-size: 14px;
  background: #035096;
  transition: background .3s ease, color .3s ease;
  cursor: pointer;
  letter-spacing: 0.04em;
}

form .submit:hover {
  background: #fff;
  color: #035096;
}

.checkbox-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
}

.styled-checkbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #035096;
}

.styled-checkbox:checked + label:before {
  background: #035096;
}

.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.hidden-container {
  max-height: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  will-change: max-height;
  transition: max-height .35s ease;
}

.parsley-errors-list {
  list-style: none;
  padding: 0;
  margin-top: 0;
}

.parsley-errors-list li {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #ff5860;
  font-style: italic;
}

.msg-container {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-top: 15px;
}

.msg-container span {
  display: block;
}

.msg-container .success {
  color: green;
}

.msg-container .error {
  color: #ff5860;
}

.msg-container a {
  color: inherit;
}

.main-footer {
  width: 100%;
  background: #000;
  padding: 15px 30px;
}

.main-footer small {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
}

.main-footer small.right {
  float: right;
}

.main-footer small.right a {
  color: #00d7ff;
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  .main-footer small.right {
    float: initial;
    margin-top: 20px;
    display: block;
  }
}
