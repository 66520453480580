body {
  padding: 85vh 0 0;
  margin: 0;
  backface-visibility: hidden;
  @include phone() {
    padding: 50vh 0 0;
  }
}

.intro-container {
  width: 100%;
  height: 85vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  @include phone() {
    height: 50vh;
  }
}

.scroll-container {
  background: #fff;
  position: relative;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.21) 0 -4px 20px 0px;
}

.intro-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: auto;
    max-width: 60vw;
  }
}


.intro-container a.scroll-down {
  padding-top: 70px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  display: inline-block;
  color: #000;
  font : normal 300 20px/1 'Lato', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s, transform 2s ease;
  transform: translate3d(0, 90px, 0);
  opacity: 0;
  &.show {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}
.intro-container a.scroll-down span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}


.over {
  transition: opacity .6s ease .3s, visibility 0s ease .9s;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
}


.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}

.box {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  padding: 72px;
  &.half {
    flex: 0 1 50vw;
    width: 50vw;
    padding-bottom: 140px;
  }
  &.text {
    h2 {
      margin-top: 60px;
      @include phone() {
        margin-top: 0;
      }
    }
  }
}

@media all and (max-width: 920px) {
  .container {
    flex-wrap: wrap;
    .box {
      flex: 0 1 100%;
      width: 100%;
      padding: 42px;
    }
  }
}

.bg-blue {
  background: $blue;
  color: #fff;
}

.is ul {
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    font-family: 'Lato', sans-serif;
    &:after {
      content: ' / ';
      display: inline-block;
      font-size: 2rem;
      margin: 0 10px;
      color: #000000;
    }

    &.small {
      font-size: 1rem;
    }
    &.medium {
      font-size: 2rem;
    }
    &.big {
      font-size: 3rem;
    }

    &:last-child:after {
      display: none;
    }

    @include phone() {
      &.small {
        font-size: 1rem;
      }
      &.medium {
        font-size: 1.5rem;
      }
      &.big {
        font-size: 2rem;
      }
    }
  }
}

.sharing-container {
  padding: 36px 20px 80px 20px;
  background: #f1f1f1;
  text-align: center;

  a {
    display: inline-block;
    height: 50px;
    width: 50px;
    margin: 0 2vw;
    @include phone() {
      margin: 0 40px 40px;
    }
    svg {
      height: 100%;
      transition: fill .2s ease;
      width: auto;
      fill: #000;
      @include phone() {
        width: 50px;
      }
    }
    &:hover svg {
      fill: $blue;
    }
  }
}